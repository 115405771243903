@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');
   
  
  
    .form-control{
      border-radius: 30px;  
      font-size: 15px;
      background: transparent !important;
      width: 40%;
      padding: 7px 5px;
      margin: auto;
      text-align: center;
      font-weight: 700;
      color: #fff;
      font-family: 'Electrolize', sans-serif !important;
      border: 1px solid #757575 !important;
    }
    .form-control::placeholder {
      color: #fff;
      opacity: 1;
  }
  @media (max-width: 768px) {
    .form-control{
      
      width: 70%;
  
    }
  }
  .css-1sgs4n2 {
    border-width: 0px !important;
  }
  .form-control:focus {
    border-color: #ffffff !important;
    outline: none; /* This removes the default browser outline */
    box-shadow: none; /* This removes any default browser shadow */
}

.css-owzf38 {
  color:#000000 !important;
}

h1,h2,h3{
  font-family: 'Electrolize', sans-serif !important;
}

 body {
  margin: auto !important;
  font-family: 'Electrolize', sans-serif !important;
  overflow: auto !important;
  background-attachment: fixed;
  }
            
      
            .colored-toast.swal2-icon-success {
              background-color: #296f00 !important;
            }
            
            .colored-toast.swal2-icon-error {
              background-color: #6c0000 !important;
            }
            
            .colored-toast.swal2-icon-warning {
              background-color: #783800 !important;
            }
            
            .colored-toast.swal2-icon-info {
              background-color: #005574 !important;
            }
            
            .colored-toast.swal2-icon-question {
              background-color: #00726a !important;
            }
            
            .colored-toast .swal2-title {
              color: rgb(101, 101, 101);
            }
            
            .colored-toast .swal2-close {
              color: rgb(35, 35, 35);
            }
            
            .colored-toast .swal2-html-container {
              color: rgb(255, 255, 255);
            }

            .popup-overlay {
              background: rgb(83, 83, 83);
              overflow: scroll !important;
            }

            .swal2-backdrop-show, .swal2-container.swal2-noanimation {
              background: rgb(0 0 0 / 93%) !important;
          }

            .swal2-html-container, .swal2-title{
             color: #ffffff;
             font-size: 20px !important;
              font-size: medium;
              font-family: 'Electrolize', sans-serif !important;
            }

            .coldrsp{
              font-family: 'Electrolize', sans-serif !important;
              font-size: medium;
            }
 
  .swal2-border-radius { -webkit-border-radius: 40 !important; -moz-border-radius: 40 !important; border-radius: 40 !important; }




.chakra-card__footer{
background: transparent !important;
}

.css-rwxgoc {
  font-size: 16px;
  color: #000000 !important;
}


.css-11a8r05{
  
  background-color: transparent !important;
  border-color: #6e6e6e !important;
  margin-top: 10px !important;
  background-color: rgba(0 0 0 / 63%) !important;
  box-shadow: none !important;

}

.css-1tccf60{
  color: #b2b2b2 !important;
  font-size: 13px !important;
}

.css-1sl53ol{
  color: #ed207c;;
  font-family: 'Electrolize', sans-serif !important;
  font-size: 20px !important;
  font-weight: 900 !important;
}

.cardtxt{
  text-align: center !important;
  font-family: 'Electrolize', sans-serif !important;
  font-weight: 900;
  color: #c0c0c0;
  font-size: 20px;
  padding: 15px;
}

.headertxt{

  color: #ffffff !important;
  font-size: 25px !important;
}


.btnsubmit{
  text-transform: uppercase;
  color: #ffffff !important;
  border-color: #757575 !important;
  background-color: transparent !important;
  
 
  margin-left: auto !important;
    margin-right: auto !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 15px !important;
    margin-bottom: 30px !important;
    border-radius: 30px !important;
}

.btnsubmit1{
  text-transform: uppercase;
  color: #ffffff !important;
  border-color: #757575 !important;
  background-color: transparent !important;
  
 
  margin-left: auto !important;
    margin-right: auto !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 15px !important;
    margin-bottom: 30px !important;
    border-radius: 30px !important;
    transition: 0.4s ease-in-out;
}


.btnsubmit1:hover {
  transform: scale(1.1); /* Correct the hover pseudo-class selector */
  transition: 0.4s ease-in-out;
}


.ju367v11{
  font-family: 'Electrolize', sans-serif !important;
}

.ju367vex {
  color: rgb(255 255 255) !important;
}
.ju367v9c {
  background: rgb(79 79 79) !important;
}


.InfoTop {
  background: transparent !important;
  font-size: 10px !important;
  border: 0px !important;
  --card-: transparent !important;

}

.InfoTop1:hover {
  transform: scale(1.2); /* Correct the hover pseudo-class selector */
}

.InfoTop1 {
  background: transparent !important;
  font-size: 10px !important;
  border: 0px !important;
  --card-: transparent !important;
  transition: 0.4s ease-in-out; /* Remove the double quotes around the transition value */
}
.Para{
font-size: 18px !important;
max-width: 80% !important;
align-content: center !important;
margin-left: auto;
margin-right: auto;
color: #929292 !important;
}

.nftmint4{
  color:#878787 !important;
  font-size: 15px !important;
}

.nftmint{
  color:#878787 !important;
  font-size: 15px !important;
}

.css-bjp8oh {
  font-size: 15px;
  color: #000000 !important;
  padding-inline: 15px;
  font-family: 'Electrolize', sans-serif !important;
  font-weight: 900 !important;
}

 .buttontxt {
  color: white !important;

 }



.css-r6z5ec{
  background-color: whitesmoke !important;
  border-radius: 30px !important;
}

.nftStake{
  color: #cfcfcf !important;

}



.InfoTop2:hover {
  transform: scale(1.1); /* Correct the hover pseudo-class selector */
}

.InfoTop2 {
  background: transparent !important;
  font-size: 10px !important;
  border: 0px !important;
  --card-: transparent !important;
  transition: 0.4s ease-in-out; /* Remove the double quotes around the transition value */
}


.responseTop{
  color: #ffffff !important;
}

.ju367veu {
  color: rgb(255 255 255 / 70%) !important;
}

.swal2-container.swal2-center>.swal2-popup {
  border-radius: 30px;
  background-color: #000 !important;
  border: 2px solid #5d5d5d !important;

}

.swal2-title {
  color: #b2b2b2 !important;
  font-weight: 900;
}

.swal2-popup {
  color: #ffffff !important;
}

.swal2-styled.swal2-confirm {
  background-color: #4b4b4b !important;
  color: #fff !important;
}

.HeadingText{
  font-family: 'Electrolize', sans-serif !important;
  font-weight: 900 !important;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("https://i.ibb.co/hRtZ7q5/black-white-background-with-white-background-black-white-background.jpg");
  background-size: cover;
  background-position: center;
}

#loadingImage {
  max-width: 100%;
  max-height: 100%;
  animation: zoomIn 5s infinite; /* Adjust the duration as needed */
}

@keyframes zoomIn {
  0% {
      transform: scale(0.5);
  }
 
  100% {
      transform: scale(1);
  }
}
